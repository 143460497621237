import React from 'react';
import './HomePage.styles.scss';
import Directory from '../../component/directory/directory.component';

const HomePage = () => (
  <div className="homepage">
    <Directory />
  </div>
);
export default HomePage;
